// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import gsap from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_drag", inline: true };
const loggerMsgs = {
  eventStart: ["card", "drag"],
  noCardFollower: ["card", "drag", "no card follower found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_drag(CARDEL) {
  // Setup...
  const { cardFollowers } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  /////////////////////////
  // Card follower anim. //
  /////////////////////////

  // Find card follower el...
  const cardID = CARDEL.dataset.id;
  const cardFollower = cardFollowers.find((el) => el.dataset.parentCardId === cardID);
  if (!cardFollower) return this.logger("warning", loggerMsgs.noCardFollower, "warning", logArgs);

  // Updt. card follower pos...
  gsap.to(cardFollower, {
    duration: 0.3,
    ease: "power3.out",
    // x: CARDEL.getBoundingClientRect().left - cardFollower.offsetWidth + CARDEL.offsetWidth / 3,
    // y: CARDEL.getBoundingClientRect().bottom - CARDEL.offsetHeight / 3,
    x: CARDEL.getBoundingClientRect().left - cardFollower.offsetWidth + CARDEL.offsetWidth / 2,
    y: CARDEL.getBoundingClientRect().bottom - 10,
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
