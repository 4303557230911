// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`selected quest.:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedQuestion(CHANGES) {
  if (!("selectedQuestion" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { selectedQuestion } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(selectedQuestion), "default", { ...logArgs, inline: false });

  ////////////////////////
  // Mode picker toggle //
  ////////////////////////

  // When a question has been selected (selected question is not null), the mode picker section
  // should be displayed. Otherwise, it should be hidden.

  const { sectionStates } = this.state;
  const sectionStates_new = sectionStates.map((s) => {
    const { id } = s;
    const is_hidden = id === "modePicker" ? !selectedQuestion : s.is_hidden;
    return { ...s, is_hidden };
  });
  this.setState({ sectionStates: sectionStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
