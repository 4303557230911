// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stCh_windowScroll_scrollPosition(ARGS) {
  // Setup...
  const { maxScrollPosition, scrollPosition } = ARGS;

  //////////////////////////////////////
  // Subpage layout drop shadow updt. //
  //////////////////////////////////////

  const innerPageLayoutWrapper = document.querySelector("[data-role='innerPageLayoutWrapper']");
  if (!innerPageLayoutWrapper) return;
  const maxDropShadowOpacity = 0.6;
  const showFullShadowThreshold = 0.3;
  const relativeScrollPercentage = scrollPosition / (maxScrollPosition * showFullShadowThreshold);
  const dropShadowOpacity = Math.min(relativeScrollPercentage, maxDropShadowOpacity);
  innerPageLayoutWrapper.style.setProperty("--dropShadowOpacity", dropShadowOpacity);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
