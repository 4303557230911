// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * Calculates the overlap ratio between two rectangles.
 *
 * @param {number} x1 - The x-coordinate of the first rectangle.
 * @param {number} y1 - The y-coordinate of the first rectangle.
 * @param {number} w1 - The width of the first rectangle.
 * @param {number} h1 - The height of the first rectangle.
 * @param {number} x2 - The x-coordinate of the second rectangle.
 * @param {number} y2 - The y-coordinate of the second rectangle.
 * @param {number} w2 - The width of the second rectangle.
 * @param {number} h2 - The height of the second rectangle.
 * @returns {number}  - The overlap ratio between the two rectangles.
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function calc_cardOverlapRatio(X1, Y1, W1, H1, X2, Y2, W2, H2) {
  const dx = Math.max(0, Math.min(X1 + W1, X2 + W2) - Math.max(X1, X2));
  const dy = Math.max(0, Math.min(Y1 + H1, Y2 + H2) - Math.max(Y1, Y2));
  const overlapArea = dx * dy;
  const maxArea = Math.max(W1 * H1, W2 * H2);
  return overlapArea / maxArea;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
