// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_filterBtn_click", inline: true };
const loggerMsgs = {
  eventStart: ["filter btn. was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_filterBtn_click(BUTTONEL) {
  // Setup...
  const { activeCardFilters: activeFilters } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Updt. active card filters...
  const filterID = BUTTONEL.dataset.filterId;
  if (!filterID) return console.warn("eh_filterBtn_click(): no filter ID found on button el.");
  if (activeFilters.includes(filterID)) {
    const activeFilters_new = activeFilters.length === 1 ? ["all"] : activeFilters.filter((ID) => ID !== filterID);
    this.setState({ activeCardFilters: activeFilters_new });
  } else this.setState({ activeCardFilters: [filterID] });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
