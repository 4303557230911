// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_filterBtn_click from "../eventHandlers/eh_filterBtn_click.js";
import eh_openFilterMenuBtn_click from "../eventHandlers/eh_openFilterMenuBtn_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_filterMenu", inline: true };
const loggerMsgs = {
  initStart: ["init. filterMenu"],
  initSuccess: ["init. filterMenu", "init. success"],
  noFilterMenuEl: ["init. filterMenu", "no filterMenu el. found"],
  noOpenFilterMenuBtnEl: ["init. filterMenu", "no openFilterMenuBtn el. found"],
  noFilterBtnsEl: ["init. filterMenu", "no filterBtns el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_filterMenu() {
  // Setup...
  const { openFilterMenuBtn, filterMenu, filterBtns } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(filterMenu)) return this.cancel_featInit(loggerMsgs.noFilterMenuEl);
  if (!this.validate_refEl(openFilterMenuBtn)) return this.cancel_featInit(loggerMsgs.noOpenFilterMenuBtnEl);
  if (filterBtns === undefined) return this.cancel_featInit(loggerMsgs.noFilterBtnsEl);
  if (filterBtns.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noFilterBtnsEl);

  // Event hdl.
  openFilterMenuBtn?.addEventListener("click", eh_openFilterMenuBtn_click.bind(this));
  filterBtns
    .filter((btnEl) => this.validate_refEl(btnEl))
    .forEach((btnEl) => btnEl.addEventListener("click", eh_filterBtn_click.bind(this, btnEl)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
