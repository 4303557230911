// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_listItem_mouseEnter from "../eventHandlers/eh_listItem_mouseEnter.js";
import eh_listItem_mouseLeave from "../eventHandlers/eh_listItem_mouseLeave.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_listItems", inline: true };
const loggerMsgs = {
  initStart: ["init. listItems"],
  initSuccess: ["init. listItems", "init. success"],
  noValidDOMEl: ["init. listItems", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_listItems() {
  // Setup...
  const { listItems } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (listItems === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (listItems.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  listItems.forEach((el) => {
    el.addEventListener("mouseenter", eh_listItem_mouseEnter.bind(this, el));
    el.addEventListener("mouseleave", eh_listItem_mouseLeave.bind(this, el));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
