// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_window_resize(ARGS) {
  // Setup...
  const { _name } = ARGS;
  const api = this.api;
  const { mobileBreakpoint = 640 } = this.options;
  this.logger("eventbus call", [_name], "event", { inline: true });

  // Updt. is_mobile state...
  const is_mobile = window.innerWidth < mobileBreakpoint;
  this.setState({ is_mobile });

  // If on mobile, disable dragging...
  if (is_mobile) api.disable_cardDrag_all();
  else api.enable_cardDrag_all();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
