// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_mouseEnter", inline: true };
const loggerMsgs = {
  eventStart: ["mouse entered cart"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_mouseEnter(CARDEL, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Ensure card has topmost z-index...
  CARDEL.style.zIndex = this.get_highestCardZIndex() + 1;

  /////////////////////////////
  // Card size randomization //
  /////////////////////////////

  if (CARDEL.dataset.expandOnHover === "true") {
    // Reveal info text el. via data-is-expanded attr...
    CARDEL.setAttribute("data-is-expanded", "true");

    // Randomize card size...
    const cardRect = CARDEL.getBoundingClientRect();
    const { width, height } = cardRect;
    const scaleFactor_w = { min: 1.2, max: 1.7 };
    const scaleFactor_h = { min: 0.8, max: 1.2 };
    const randScaleFactor_width = scaleFactor_w.min + Math.random() * (scaleFactor_w.max - scaleFactor_w.min);
    const randScaleFactor_height = scaleFactor_h.min + Math.random() * (scaleFactor_h.max - scaleFactor_h.min);
    const width_new = width * randScaleFactor_width;
    const height_new = height * randScaleFactor_height;
    CARDEL.style.width = `${width_new}px`;
    CARDEL.style.height = `${height_new}px`;
  }

  ////////////////////////////////////
  // Card SVG backgr./border expand //
  ////////////////////////////////////

  if (CARDEL.dataset.hasExpandingBg === "true") {
    const animDuration = 0.333;

    // Backgr. expand //
    const bgSVG = CARDEL.querySelector("[data-role='cardBgShape']");
    if (bgSVG) {
      const bgShape = CARDEL.dataset.bgShape;
      const elToExpand = bgSVG.querySelector(bgShape == "angular" ? "polygon" : "ellipse");
      if (elToExpand) {
        switch (bgShape) {
          case "angular":
            const points_expanded = elToExpand.dataset.pointsExpanded;
            gsap.to(elToExpand, { duration: animDuration, attr: { points: points_expanded }, ease: "power2.inOut" });
            break;
          case "ellipse":
            const clipPathToExpand = bgSVG.getElementById("ellipseClip");
            const ellipseEl = clipPathToExpand.querySelector("ellipse");
            gsap.to(ellipseEl, { attr: { rx: 100, ry: 100 }, duration: animDuration });
            break;
        }
      }
    }

    // Border expand //
    const borderSVG = CARDEL.querySelector("[data-role='cardBorderShape']");
    if (borderSVG) {
      const borderShape = CARDEL.dataset.bgShape;
      const elToExpand = borderSVG.querySelector("[data-role='cardBorderPath']");
      if (elToExpand) {
        switch (borderShape) {
          case "angular":
            const d_expanded = elToExpand.dataset.dExpanded;
            gsap.to(elToExpand, { duration: animDuration, attr: { d: d_expanded }, ease: "power2.inOut" });
            break;
          case "ellipse":
            console.warn("Card border shape 'ellipse' not yet supported");
            break;
        }
      }
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
