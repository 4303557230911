// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default {
  from: "/lang",
  to: "card-expand",
  out: async (FN_done, EVENTDATA) => {
    // Find + expand card el...
    const visitData = EVENTDATA.visit;
    const triggerEl = visitData.trigger.el;
    const cardEl = triggerEl.closest(".card");
    cardEl.setAttribute("data-is-expanding", "true");
    await gsap.to(cardEl, {
      duration: 0.625,
      width: "100vw",
      height: "100vh",
      "--borderR": "0",
      x: 0,
      y: 0,
      zIndex: 1000,
      ease: "power3.inOut",
    });
  },
  in: async () => {
    await gsap.fromTo("#swup", { opacity: 0 }, { opacity: 1, duration: 0.25 });
  },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
