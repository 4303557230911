// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * Determ. best position for an element on the page with a minimum distance between elements.
 *
 * @param {HTMLElement} element                                                    - The element to place.
 * @param {number} pageWidth                                                       - The width of the page in pixels.
 * @param {number} pageHeight                                                      - The height of the page in pixels.
 * @param {Array<{x: number, y: number, width: number, height: number}>} positions - The positions of the already placed elements.
 * @param {number} minDistance                                                     - The minimum distance between elements.
 * @returns {{position: {x: number, y: number}, distance: number}}                 - The best position for the element and the distance to the nearest element.
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_cardDistance from "./calc_cardDistance";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function determ_bestCardPosition_withMinDist(
  EL,
  PAGEWIDTH = window.innerWidth,
  PAGEHEIGHT = window.innerHeight,
  POSITIONS,
  CONFIG = { minDistance: 0 }
) {
  if (!EL) return console.warn("determ_bestCardPosition(): No element provided.");
  if (!POSITIONS) return console.warn("determ_bestCardPosition(): No positions provided.");

  // Setup...
  const { minDistance } = CONFIG;
  const elWidth = EL.offsetWidth;
  const elHeight = EL.offsetHeight;
  let bestPosition = null;
  let bestDistance = 0;

  // Try to determ. best position (up to 10 attempts)...
  for (let i = 0; i < 10; i++) {
    const x = Math.floor(Math.random() * (PAGEWIDTH - elWidth));
    const y = Math.floor(Math.random() * (PAGEHEIGHT - elHeight));
    let distance = Infinity;

    for (const pos of POSITIONS) {
      const dist = calc_cardDistance(x, y, elWidth, elHeight, pos.x, pos.y, pos.width, pos.height);
      distance = Math.min(distance, dist);
    }

    if (bestPosition === null || distance >= minDistance) {
      bestPosition = { x, y, width: elWidth, height: elHeight };
      bestDistance = distance;
    }
  }

  return { position: bestPosition, distance: bestDistance };
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
