// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_eventCard_click from "../eventHandlers/eh_eventCard_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_eventCards", inline: true };
const loggerMsgs = {
  initStart: ["init. eventCards"],
  initSuccess: ["init. eventCards", "init. success"],
  noValidDOMEl: ["init. eventCards", "no valid DOM element(s) provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_eventCards() {
  // Setup...
  const { eventCards } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (eventCards === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (eventCards.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Event hdl...
  eventCards.forEach((el) => el.addEventListener("click", eh_eventCard_click.bind(this, el)));

  // Set init. event card states...
  const eventCardStates = eventCards.map((el) => ({ id: el.dataset.id, el, is_collapsed: true }));
  this.setState({ eventCardStates });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
