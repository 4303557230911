// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`menu state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_menuState(CHANGES) {
  if (!("menuState" in CHANGES)) return;

  // Setup...
  const { menuState } = CHANGES;
  const { is_open } = menuState;
  const { menu, menuBtn } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(menuState));

  // Toggle data-is-closed attr. on menu el...
  menu.setAttribute("data-is-closed", !is_open);

  // Toggle data-is-active attr. on menuBtn el...
  menuBtn.setAttribute("data-is-active", is_open);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
