// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_eventCard_click", inline: true };
const loggerMsgs = {
  eventStart: ["event card was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_eventCard_click(CARDEL, EVENTDATA) {
  // Setup...
  const { eventCardStates } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Toggle card’s is_collapsed state (eventCardStates updt.)...
  const eventCardStates_new = eventCardStates.map((state) => {
    if (state.id === CARDEL.dataset.id) state.is_collapsed = !state.is_collapsed;
    return { ...state };
  });
  this.setState({ eventCardStates: eventCardStates_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
