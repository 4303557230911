// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`section states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_sectionStates(CHANGES) {
  if (!("sectionStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { sectionStates } = CHANGES;
  const { sections } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(sectionStates), "default", { ...logArgs, inline: false });

  //////////////////////////////
  // Section visibility updt. //
  //////////////////////////////

  sectionStates?.forEach((state) => {
    const { id, is_hidden } = state;
    const section = sections.find((el) => el.getAttribute("data-id") === id);
    if (section) section.setAttribute("data-is-hidden", is_hidden);
  });

  // Updt. instruct.-card states
  // (reveal / hide instruct.- cards related to hidden / revealed sections)...
  // const instructCardStates = this.state.instructCardStates || [];
  // const instructCardStates_new = instructCardStates.map((state) => {
  //   const { relatedSectionId } = state;
  //   const is_hidden = sectionStates.find((s) => s.id === relatedSectionId)?.is_hidden;
  //   return { ...state, is_hidden };
  // });
  // this.setState({ instructCardStates: instructCardStates_new });

  // If "drawing" section is revealed, refresh canvas size...
  // const drawingSectionIsVisible = !sectionStates.find((s) => s.id === "drawing")?.is_hidden;
  // if (drawingSectionIsVisible) this.api.refresh_canvasSize();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
