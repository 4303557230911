// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.expand_card", inline: true };
const loggerMsgs = {
  eventStart: ["DragCards", "API", "expand_card"],
  noCardEl: ["DragCards", "API", "expand_card", "no valid card el. provided"],
};

const defaultConfig = {
  duration: 0.5,
  width: "100vw",
  height: "100vh",
  x: 0,
  y: 0,
  zIndex: 1000,
  ease: "power3.inOut",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function expand_card(CARDEL, CONFIG = defaultConfig) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Animated expanding of card el...
  gsap.to(CARDEL, CONFIG);
  CARDEL.setAttribute("data-is-expanded", "true");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
