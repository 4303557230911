// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function validate_infoText(TEXT) {
  if (TEXT === undefined || !TEXT || typeof TEXT !== "string" || TEXT.length === 0) return false;
  return true;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`info display state:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_infoDisplayState(CHANGES) {
  if (!("infoDisplayState" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { infoDisplayState } = CHANGES;
  const { infoDisplay } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(infoDisplayState), "default", { ...logArgs, inline: false });

  // Updt. info display text...
  const { text } = infoDisplayState;
  if (!validate_infoText(text)) infoDisplay.innerHTML = "";
  else {
    const decodedText = decodeURIComponent(text.replace(/\\u/g, "%")); // ← decode unicode characters
    infoDisplay.innerHTML = decodedText;
  }

  // Updt. info display position...
  const { position } = infoDisplayState;
  if (position) {
    const { x, y } = position;
    infoDisplay.style.transform = `translate(${x}px, ${y}px)`;
  }

  // Updt. info display colors...
  const { colors } = infoDisplayState;
  if (colors) {
    const { bg, txt } = colors;
    infoDisplay.style.setProperty("--color-bg", bg);
    infoDisplay.style.setProperty("--color-txt", txt);
  }

  // Updt. info display visibility...
  const is_hidden = infoDisplayState.is_hidden ?? true;
  infoDisplay.setAttribute("data-is-hidden", is_hidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
