// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { fabric } from "fabric";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_canvas_mouseDown from "../eventHandlers/eh_canvas_mouseDown.js";
import eh_canvas_mouseMove from "../eventHandlers/eh_canvas_mouseMove.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_fabric", inline: true };
const loggerMsgs = {
  initStart: ["init. fabric"],
  initSuccess: ["init. fabric", "init. success"],
  noValidDOMEl: ["init. fabric", "no valid DOM element(s) provided"],
  noCanvasEl: ["init. fabric", "no valid canvas element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_fabric(CANVASEL) {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CANVASEL)) return this.cancel_featInit(loggerMsgs.noCanvasEl);

  // fabric instantiation...
  const fabricInst = new fabric.Canvas(CANVASEL, {
    backgroundColor: "rgba(255, 255, 255, 1)",
    selection: false,
    width: CANVASEL.offsetWidth,
    height: CANVASEL.offsetHeight,
    isDrawingMode: true,
    freeDrawingCursor: "none",
  });
  fabricInst.freeDrawingBrush.width = 5;
  fabricInst.renderAll();

  // Set init. drawing module state...
  const drawingModuleState = { fabricInst, isDrawing: false, brushColor: "#000", brushWidth: 5 };
  this.setState({ drawingModuleState });

  // Event hdl...
  fabricInst.on("mouse:move", eh_canvas_mouseMove.bind(this, { fabricInst }));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
  this.modules.fabric.instances.push(fabricInst);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
