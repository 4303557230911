// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import JustValidate from "just-validate";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_contactForm", inline: true };
const loggerMsgs = {
  initStart: ["init. contactForm"],
  initSuccess: ["init. contactForm", "init. success"],
  noValidDOMEl: ["init. contactForm", "no valid DOM element(s) provided"],
  noShowContactFormBtn: ["init. contactForm", "no show-contact-form button found"],
  noContactFormFollower: ["init. contactForm", "no contact-form follower found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_contactForm(CARDEL) {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  //////////////////////////////////
  // Contact form follower intgr. //
  //////////////////////////////////

  // Get open-modal btn. el...
  const showContactFormBtn = CARDEL.querySelector('[data-role="showContactFormBtn"]');
  if (!showContactFormBtn) return this.cancel_featInit(loggerMsgs.noShowContactFormBtn);

  // Show/hide contact form hdl...
  const cardID = CARDEL.dataset.id;
  const contactFormFollowerEl = document.querySelector(`[g-ref="DragCards:cardFollowers"][data-parent-card-id="${cardID}"]`);
  if (!contactFormFollowerEl) return this.cancel_featInit(loggerMsgs.noContactFormFollower);
  showContactFormBtn.addEventListener("click", () => contactFormFollowerEl.setAttribute("data-is-hidden", false));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
