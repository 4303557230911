// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_card_click from "../eventHandlers/eh_card_click.js";
import eh_card_mouseLeave from "../eventHandlers/eh_card_mouseLeave.js";
import eh_card_mouseEnter from "../eventHandlers/eh_card_mouseEnter.js";
import eh_card_drag from "../eventHandlers/eh_card_drag.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cards", inline: true };
const loggerMsgs = {
  initStart: ["init. cards"],
  initSuccess: ["init. cards", "init. success"],
  noValidDOMEl: ["init. cards", "no valid DOM element provided"],
  setInitPos: ["init. cards", "setting init. card positions"],
  formatCardData: ["init. cards", "formatting card data"],
  registerDragPlugin: ["init. cards", "registering GSAP Draggable plugin"],
};
const initCardState = { is_hidden: false, is_hovered: false, is_dragging: false, is_expanded: false };

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cards() {
  // Setup...
  const { cards: cardElements, cardFollowers } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (cardElements.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  /////////////////////
  // Card data prep. //
  /////////////////////

  // Format card data (extract info. needed to init. card from card els.)...
  this.logger("init", loggerMsgs.formatCardData, "action", logArgs);
  const cardData = this.format_cardData(cardElements);

  ////////////////
  // Card init. //
  ////////////////

  const cardStates = []; // ← used to store card states during card init.
  cardData.forEach((c) => {
    // Setup...
    const { el, config } = c;
    const { type, with_marquee, with_popUp, with_follower, followerConfig, mediaSrcs, with_animBorder } = config;
    const { src_audioFile = null } = mediaSrcs ?? {};
    const with_podcast = config.with_podcast && src_audioFile;
    if (!el) return console.warn("init. card: No valid DOM el. found");

    ////////////////
    // Event hdl. //
    ////////////////

    el.addEventListener("click", eh_card_click.bind(this, el));
    el.addEventListener("mouseenter", eh_card_mouseEnter.bind(this, el));
    el.addEventListener("mouseleave", eh_card_mouseLeave.bind(this, el));

    ///////////////////
    // Feature init. //
    ///////////////////

    if (with_marquee) this.init_marquee(el);
    if (with_podcast) this.init_audioPlayer(el, src_audioFile);
    // if (type == "cta" && with_popUp) this.init_ctaPopup(el); // ← DEPRECATED
    if (type == "contactForm") this.init_contactForm(el);
    if (type == "surveyForm") this.init_surveyForm(el);
    if (with_follower) this.init_cardFollower(el, followerConfig);
    if (with_animBorder) this.init_animCardBorder(el);

    /////////////////////
    // Visual adjustm. //
    /////////////////////

    // ...

    ///////////////////////
    // State formulation //
    ///////////////////////

    const cardState = { el, config, state: { ...initCardState } };
    cardStates.push(cardState);
  });

  // Set init. card states...
  this.setState({ cardStates });

  /////////////////////
  // Draggable init. //
  /////////////////////

  // Set random init. card positions...
  this.logger("init", loggerMsgs.setInitPos, "action", logArgs);
  this.set_initCardPositions(cardElements);

  // Register Draggable plugin...
  this.logger("init", loggerMsgs.registerDragPlugin, "action", logArgs);
  gsap.registerPlugin(Draggable);

  // Create Draggable instances for each card...
  const instConfig = { type: "x,y", edgeResistance: 0.65, inertia: true };
  const dragCards = cardData.map((c) => ({
    id: c.el.dataset.id,
    el: c.el,
    dragInst: Draggable.create(c.el, {
      ...instConfig,
      onDragStart: () => (c.el.style.zIndex = this.get_highestCardZIndex() + 1),
      onDrag: eh_card_drag.bind(this, c.el),
    }),
  }));
  this.setState({ cardDragEnabled: true });

  // If on mobile, disable dragging...
  // const is_mobile = window.innerWidth < 640;
  // if (is_mobile) dragCards.forEach((c) => c.dragInst[0].disable());

  ///////////////////////
  // INTERIM SITE ONLY //
  ///////////////////////

  // On mobile, move cards below CardArrangment comp...
  // if (is_mobile) {
  //   const cardArrangmEl = document.querySelector("[g-component='CardArrangement']");
  //   if (cardArrangmEl) {
  //     setTimeout(() => {
  //       console.log("CardArrangmEl found");
  //       // Get last card in cardArrangmEl (card ID 13)...
  //       const lastCard = cardArrangmEl.querySelector("[g-ref='cards'][data-id='13']");

  //       // Adjust cards absolute position to be stacked below last card of CardArrangmEl...
  //       if (lastCard) {
  //         console.log("Last card found");
  //         const lastCardRect = lastCard.getBoundingClientRect();
  //         const lastCardBottom = lastCardRect.bottom;
  //         dragCards.forEach((c) => {
  //           const cardEl = c.el;
  //           cardEl.style.transform = "none";
  //           cardEl.style.top = `${lastCardBottom + 40}px`;

  //           // Adjust left to center cards...
  //           const cardRect = cardEl.getBoundingClientRect();
  //           const viewportWidth = window.innerWidth;
  //           const cardWidth = cardRect.width;
  //           const cardLeft = (viewportWidth - cardWidth) / 2;
  //           cardEl.style.left = `${cardLeft}px`;

  //           // Move card follower below card...
  //           const cardID = cardEl.dataset.id;
  //           const cardFollower = cardFollowers.find((el) => el.dataset.parentCardId === cardID);
  //           console.log("Card follower found");
  //           if (cardFollower) {
  //             const parentCardBottom = cardRect.bottom;
  //             cardFollower.style.transform = "none";
  //             cardFollower.style.top = `${parentCardBottom + 20}px`;

  //             // Adjust left to center card follower...
  //             const cardFollowerRect = cardFollower.getBoundingClientRect();
  //             const cardFollowerWidth = cardFollowerRect.width;
  //             const cardFollowerLeft = (viewportWidth - cardFollowerWidth) / 2;
  //             cardFollower.style.left = `${cardFollowerLeft}px`;
  //           }
  //         });
  //       }
  //     }, 500);
  //   }
  // }

  ///////////////////////
  // INTERIM SITE ONLY //
  ///////////////////////

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
  this.modules.cards.instances = dragCards;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
