// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_click", inline: true };
const loggerMsgs = {
  eventStart: ["card was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_click(CARDEL, EVENTDATA) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Enable clicked input or textarea (if applicable)...
  const clickTarget = EVENTDATA.target;
  const isInput = clickTarget.tagName === "INPUT";
  const isTextarea = clickTarget.tagName === "TEXTAREA";
  if (isInput || isTextarea) clickTarget.focus();

  // Trigger click on submit-btn. (if applicable)...
  const isSubmit = clickTarget.tagName === "BUTTON";
  if (isSubmit) clickTarget.click();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
