// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeCardFilters", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`active card filters:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeCardFilters(CHANGES) {
  if (!("activeCardFilters" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { activeCardFilters } = CHANGES;
  const { filterBtns } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeCardFilters), "default", { ...logArgs, inline: false });

  // Filter cards...
  this.api.filter_cards(activeCardFilters);

  // Toggle filter btn active styles...
  filterBtns.forEach((btn) => {
    const is_active = activeCardFilters.includes(btn.dataset.filterId);
    btn.setAttribute("data-is-active", is_active);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
