// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import determ_bestCardPosition_withMinDist from "../util/determ_bestCardPosition_withMinDist";
import determ_bestCardPosition_withOverlap from "../util/determ_bestCardPosition_withOverlap";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.distribute_cards", inline: true };
const loggerMsgs = {
  eventStart: ["DragCards", "API", "distribute_cards"],
  noCardEls: ["DragCards", "API", "distribute_cards", "no card elements provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function distribute_cards(
  CARDELS,
  PAGEWIDTH = window.innerWidth,
  PAGEHEIGHT = window.innerHeight,
  CONFIG = {
    placementMethod: "maxOverlap",
    minDistance: 50,
    maxOverlap: 0.0,
    largeOverlapThreshold: 0.5,
  }
) {
  if (!CARDELS || !CARDELS.length) return this.logger("warning", loggerMsgs.noCardEls, "warning", logArgs);

  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  const { placementMethod, minDistance, maxOverlap, largeOverlapThreshold } = CONFIG;
  const positions = [];
  const unplacedCardEls = [];
  const pageExtensionFactor = 1.1;
  let currentPageHeight = PAGEHEIGHT;
  const sortedCardEls = [...CARDELS].sort((a, b) => {
    const aArea = a.offsetWidth * a.offsetHeight;
    const bArea = b.offsetWidth * b.offsetHeight;
    return bArea - aArea; // ← sort by descending total area
  });

  ////////////////////////////
  // min. distance approach //
  ////////////////////////////

  if (placementMethod === "minDistance") {
    // Try to distribute cards...
    for (const el of sortedCardEls) {
      const { position, distance } = determ_bestCardPosition_withMinDist(
        el,
        PAGEWIDTH,
        currentPageHeight,
        positions,
        minDistance
      );

      if (position) {
        positions.push(position);
        el.style.transform = `translate(${position.x}px, ${position.y}px)`;
        if (distance < minDistance) el.classList.add("tooClose");
      } else {
        console.warn(`Unable to place element with size ${el.offsetWidth}x${el.offsetHeight}`);
        el.classList.add("couldNotbePlaced");
        unplacedCardEls.push(el);
      }
    }

    // Retry placing unplaced cards...
    while (unplacedCardEls.length > 0) {
      const el = unplacedElements.shift();
      const { position, distance } = determ_bestCardPosition_withMinDist(
        el,
        PAGEWIDTH,
        currentPageHeight,
        positions,
        minDistance
      );

      if (position) {
        positions.push(position);
        el.style.transform = `translate(${position.x}px, ${position.y}px)`;
        if (distance < minDistance) el.classList.add("tooClose");
      } else {
        console.warn(`Unable to place element with size ${el.offsetWidth}x${el.offsetHeight}`);
        el.classList.add("couldNotbePlaced");
        currentPageHeight *= pageExtensionFactor;
      }
    }

    ///////////////////////////
    // max. overlap approach //
    ///////////////////////////
  } else if (placementMethod === "maxOverlap") {
    // Try to distribute cards...
    for (const el of sortedCardEls) {
      const { position, hasLargeOverlap } = determ_bestCardPosition_withOverlap(
        el,
        PAGEWIDTH,
        currentPageHeight,
        positions,
        CONFIG
      );
      if (position) {
        positions.push(position);
        el.style.transform = `translate(${position.x}px, ${position.y}px)`;
        if (hasLargeOverlap) el.classList.add("hasLargeOverlap");
      } else {
        console.warn(`Unable to place element with size ${el.offsetWidth}x${el.offsetHeight}`);
        unplacedCardEls.push(el);
      }
    }

    // Retry placing unplaced cards...
    while (unplacedCardEls.length > 0) {
      const el = unplacedCardEls.shift();
      const { position, hasLargeOverlap } = determ_bestCardPosition_withOverlap(
        el,
        PAGEWIDTH,
        currentPageHeight,
        positions,
        CONFIG
      );
      if (position) {
        positions.push(position);
        el.style.transform = `translate(${position.x}px, ${position.y}px)`;
        if (hasLargeOverlap) el.classList.add("hasLargeOverlap");
      } else {
        console.warn(`Unable to place element with size ${el.offsetWidth}x${el.offsetHeight}`);
        currentPageHeight *= pageExtensionFactor;
      }
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
