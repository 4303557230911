// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_selectedDrawTool", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`selected drawing tool: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["pencil", "spraycan", "none"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_selectedDrawTool(CHANGES) {
  if (!("selectedDrawTool" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.selectedDrawTool))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.selectedDrawTool), "error", logArgs);

  // Setup...
  const { selectedDrawTool } = CHANGES;
  const fabricInst = this.modules.fabric.instances[0];
  this.logger("state-change", loggerMsgs.stateChange(selectedDrawTool), "default", logArgs);

  // If selected tool is 'spraycan', updt. fabric inst. brush...
  if (selectedDrawTool === "spraycan") fabricInst.freeDrawingBrush = new fabric.SprayBrush(fabricInst);

  // If selected tool is 'pencil', updt. fabric inst. brush...
  if (selectedDrawTool === "pencil") fabricInst.freeDrawingBrush = new fabric.PencilBrush(fabricInst);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
