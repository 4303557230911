// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_eventCardStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`event card states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_eventCardStates(CHANGES) {
  if (!("eventCardStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { eventCardStates } = CHANGES;
  const { eventListItems } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(eventCardStates), "default", { ...logArgs, inline: false });

  // Execute state changes...
  eventCardStates.forEach((state) => {
    const { el, is_collapsed } = state;

    // Execute is_collapsed state change...
    const relatedListItem = eventListItems.find((i) => i.dataset.id === el.dataset.id);
    el.setAttribute("data-is-collapsed", is_collapsed);
    if (relatedListItem) relatedListItem.setAttribute("data-is-active", !is_collapsed);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
