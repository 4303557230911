// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.disable_cardDrag", inline: true };
const loggerMsgs = {
  eventStart: ["DragCards", "API", "disable_cardDrag"],
  noCardEl: ["DragCards", "API", "expand_card", "no valid card el. provided"],
  noDragCardInst: ["DragCards", "API", "disable_cardDrag", "no drag. card instance(s) found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function disable_cardDrag(CARDEL) {
  // Setup...
  const dragCardInstances = this?.modules?.cards?.instances;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (!dragCardInstances || !dragCardInstances.length) return this.cancel_featInit(loggerMsgs.noDragCardInst);

  // Find & disable drag. card inst. corresponding to card el...
  const cardInst = dragCardInstances.find((inst) => inst.id === CARDEL.dataset.id);
  if (!cardInst) return this.cancel_featInit(loggerMsgs.noDragCardInst);
  cardInst.dragInst[0].disable();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
