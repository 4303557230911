// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`instruct. card states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_instructCardStates(CHANGES) {
  if (!("instructCardStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { instructCardStates } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(instructCardStates), "default", { ...logArgs, inline: false });

  /////////////////////////////////
  // Instruct.-card hidden updt. //
  /////////////////////////////////

  instructCardStates?.forEach((s) => {
    const { el, is_hidden } = s;
    if (el) {
      el.setAttribute("data-is-hidden", is_hidden);
    }
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
