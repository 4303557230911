// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_initCardPositions(CARDELS) {
  if (!CARDELS || !CARDELS.length) return console.warn("set_initCardPositions(): No valid card els. provided");

  // Setup...
  const pageContentWrapper = document.getElementById("pageContentWrapper");
  const { width: pageWidth, height: pageHeight } = pageContentWrapper.getBoundingClientRect();
  const { cardPlacmentMethod = "maxOverlap" } = this.options;

  // Distribute cards...
  this.api.distribute_cards(CARDELS, pageWidth, pageHeight, {
    placementMethod: cardPlacmentMethod,
    minDistance: 50,
    maxOverlap: 0.0,
  });

  // CARDELS.forEach((el) => {
  //   const vpWidth = window.innerWidth;
  //   const vpHeight = window.innerHeight;
  //   const x = Math.random() * (vpWidth - el.offsetWidth);
  //   const y = Math.random() * (vpHeight - el.offsetHeight);
  //   el.style.top = `${y}px`;
  //   el.style.left = `${x}px`;

  //   // Set card follow pos. (if applicable)...
  //   const followerEl = this.get_cardFollowerEl(el);
  //   if (!followerEl) return;
  //   const { height } = el.getBoundingClientRect();
  //   const fX = x - followerEl.offsetWidth / 2;
  //   const fY = y + height - followerEl.offsetHeight / 2;
  //   followerEl.style.transform = `translate(${fX}px, ${fY}px)`; // ← move follower to card's bottom left corner
  // });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
