// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_cardStates", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`card states:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_cardStates(CHANGES) {
  if (!("cardStates" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { cardStates } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(cardStates), "default", { ...logArgs, inline: false });

  // Execute state changes...
  cardStates.forEach((cardState) => {
    const { el, config, state } = cardState;
    const { is_hidden = false, is_hovered = false, is_dragging = false, is_expanded = false } = state;

    // Execute is_hidden state change...
    el.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
