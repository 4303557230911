// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { gsap } from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_card_mouseLeave", inline: true };
const loggerMsgs = {
  eventStart: ["mouse left cart"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_card_mouseLeave(CARDEL) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  /////////////////////
  // Card size reset //
  /////////////////////

  // Stop, if card is of type participation...
  if (CARDEL.getAttribute("data-type") === "participation") return;
  CARDEL.style.width = "";
  CARDEL.style.height = "";

  /////////////////////////////
  // Card SVG backgr. reset //
  /////////////////////////////

  if (CARDEL.dataset.hasExpandingBg === "true") {
    const animDuration = 0.333;

    // Backgr. reset //
    const bgSVG = CARDEL.querySelector("[data-role='cardBgShape']");
    if (bgSVG) {
      const bgShape = CARDEL.dataset.bgShape;
      const elToCollapse = bgSVG.querySelector(bgShape == "angular" ? "polygon" : "ellipse");
      if (elToCollapse) {
        switch (bgShape) {
          case "angular":
            const points_initial = elToCollapse.dataset.pointsInitial;
            gsap.to(elToCollapse, { duration: animDuration, attr: { points: points_initial }, ease: "power2.inOut" });
            break;
          case "ellipse":
            const clipPathToExpand = bgSVG.getElementById("ellipseClip");
            const ellipseEl = clipPathToExpand.querySelector("ellipse");
            gsap.to(ellipseEl, { duration: animDuration, attr: { rx: 50, ry: 50 }, ease: "power1.inOut" });
            break;
        }
      }
    }

    // Border reset //
    const borderSVG = CARDEL.querySelector("[data-role='cardBorderShape']");
    if (borderSVG) {
      const borderShape = CARDEL.dataset.bgShape;
      const elToCollapse = borderSVG.querySelector("[data-role='cardBorderPath']");
      if (elToCollapse) {
        switch (borderShape) {
          case "angular":
            const d_initial = elToCollapse.dataset.dInitial;
            gsap.to(elToCollapse, { duration: animDuration, attr: { d: d_initial }, ease: "power2.inOut" });
            break;
          case "ellipse":
            console.warn("Card border shape 'ellipse' not yet supported");
            break;
        }
      }
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
