// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import gsap from "gsap";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_cardFollower", inline: true };
const loggerMsgs = {
  initStart: ["init. cardFollower"],
  initSuccess: ["init. cardFollower", "init. success"],
  noValidDOMEl: ["init. cardFollower", "no valid DOM element(s) provided"],
  noFollowerEl: ["init. cardFollower", "no follower element found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_cardFollower(CARDEL, CONFIG) {
  // Setup...
  // const { type, subtype } = CONFIG;
  const { cardFollowers } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);
  if (!this.validate_refEl(CARDEL)) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  // Find related follower el...
  const followerEl = this.get_cardFollowerEl(CARDEL);
  if (!followerEl) return this.cancel_featInit(loggerMsgs.noFollowerEl);

  ////////////////////
  // Position init. //
  ////////////////////

  // Find card follower el...
  const cardID = CARDEL.dataset.id;
  const cardFollower = cardFollowers.find((el) => el.dataset.parentCardId === cardID);
  if (!cardFollower) return this.logger("warning", loggerMsgs.noCardFollower, "warning", logArgs);

  // Set card follower pos...
  setTimeout(() => {
    gsap.to(cardFollower, {
      duration: 0.3,
      ease: "power3.out",
      // x: CARDEL.getBoundingClientRect().left - cardFollower.offsetWidth + CARDEL.offsetWidth / 3,
      // y: CARDEL.getBoundingClientRect().bottom - CARDEL.offsetHeight / 3,
      x: CARDEL.getBoundingClientRect().left - cardFollower.offsetWidth + CARDEL.offsetWidth / 2,
      y: CARDEL.getBoundingClientRect().bottom - 10,
    });
  }, 100);

  ///////////////////
  // Feature init. //
  ///////////////////

  // if (type == "cta" && subtype == "popUp") this.init_ctaPopup(followerEl); // ← DEPRECATED

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
