// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * @description Function to handle changes to the mode state.
 *              The mode state may be changed to one of the following values:
 *
 *              - "init"     → the component’s init. functions are about to be called
 *
 *              - "ready"    → all component’s init. functions have been called, but it is inactive
 *                           → interactions are disabled (no pointer events on entire component el.)
 *                           → scrolling is disabled (scroll instance is stopped)
 *
 *              - "active"   → the component is active;
 *                           → interactions are enabled
 *
 *              - "inactive" → interactions are disabled
 *
 *              The mode state changes at the occurence of the following events:
 *
 *              - "EVENTBUSCALL" → "inactive" when ...,
 *                               → "active" when ...,
 *
 * @param {Object} CHANGES - Object containing the changes to the mode state.
 **/

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`mode: ${CHANGE}`],
  error_noStateChange: () => ["No change to mode state provided."],
  error_invalidStateValue: (VALUE) => [`Invalid state value: ${VALUE}`],
};

const possibleStateValues = ["init", "ready", "active", "inactive", "drawing"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mode(CHANGES) {
  if (!("mode" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.mode))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.mode), "error", logArgs);

  // Setup...
  const { mode } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(mode), "default", logArgs);

  ///////////////////////////////////////
  // 'drawing' mode specific behaviour //
  ///////////////////////////////////////

  // Reveal drawing section...
  if (mode === "drawing") {
    const { sectionStates } = this.state;
    const sectionStates_new = sectionStates.map((s) => {
      const { id } = s;
      const is_hidden = id === "drawing" ? false : s.is_hidden;
      return { ...s, is_hidden };
    });
    this.setState({ sectionStates: sectionStates_new });
    eventbus.emit("WindowScroll.api.scroll_toEndOfPage"); // ← scroll to end of page
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
