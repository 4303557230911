// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * Calculates the distance between two rectangles.
 *
 * @param {number} x1 - The x-coordinate of the first rectangle.
 * @param {number} y1 - The y-coordinate of the first rectangle.
 * @param {number} w1 - The width of the first rectangle.
 * @param {number} h1 - The height of the first rectangle.
 * @param {number} x2 - The x-coordinate of the second rectangle.
 * @param {number} y2 - The y-coordinate of the second rectangle.
 * @param {number} w2 - The width of the second rectangle.
 * @param {number} h2 - The height of the second rectangle.
 * @returns {number}  - The distance between the two rectangles.
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function calc_cardDistance(X1, Y1, W1, H1, X2, Y2, W2, H2) {
  const dx = Math.max(0, Math.abs(X1 - X2) - (W1 + W2) / 2);
  const dy = Math.max(0, Math.abs(Y1 - Y2) - (H1 + H2) / 2);
  return Math.sqrt(dx * dx + dy * dy);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
