// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_canvas_mouseMove", inline: true };
const loggerMsgs = {
  eventStart: ["mouse is moving on canvas"],
  noFabricInst: ["no fabric inst. available"],
  noCursorEl: ["no cursor el. found"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_canvas_mouseMove(ARGS = { fabricInst: null }, EVENTDATA) {
  // Setup...
  const { fabricInst } = ARGS;
  const { drawingCanvasWrapper, drawingCursor } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard...
  if (!fabricInst) return this.logger("warning", loggerMsgs.noFabricInst, "warning", logArgs);
  if (!this.validate_refEl(drawingCursor)) return this.logger("warning", loggerMsgs.noCursorEl, "warning", logArgs);

  // Updt. cursor position...
  const wrapperRect = drawingCanvasWrapper.getBoundingClientRect();
  const canvasRect = fabricInst.getElement().getBoundingClientRect();
  const dist_canvasTop_wrapperTop = canvasRect.top - wrapperRect.top;
  const pointer = fabricInst.getPointer(EVENTDATA.e);
  const offset = fabricInst.freeDrawingBrush.width / 2;
  drawingCursor.style.left = `${pointer.x - offset}px`;
  drawingCursor.style.top = `${pointer.y + dist_canvasTop_wrapperTop - offset}px`;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
