// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import { computePosition, autoUpdate } from "@floating-ui/dom";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "init_instructCards", inline: true };
const loggerMsgs = {
  initStart: ["init. instructCards"],
  initSuccess: ["init. instructCards", "init. success"],
  noValidDOMEl: ["init. instructCards", "no valid DOM element(s) provided"],
  cardPosUpdt: (CARDID) => ["updt. instruct.-card pos.", `card id: ${CARDID}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_instructCards() {
  // Setup...
  const compEl = this.element;
  const { instructCards } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action", logArgs);

  // Guard...
  if (instructCards === undefined) return this.cancel_featInit(loggerMsgs.noValidDOMEl);
  if (instructCards.every((el) => !this.validate_refEl(el))) return this.cancel_featInit(loggerMsgs.noValidDOMEl);

  /////////////////////////////////////////
  // init. instruct.-card-state creation //
  /////////////////////////////////////////

  const instructCardStates = instructCards.map((cardEl) => {
    // Setup...
    const id = cardEl.dataset.id;
    const relatedSectionId = cardEl.dataset.relatedSectionId;
    const relatedStepId = cardEl.dataset.relatedStepId;

    // Create card pos. auto-updater + set init. pos...
    autoUpdate(compEl, cardEl, () => {
      // Setup...
      this.logger("action", loggerMsgs.cardPosUpdt(id), "action", { ...logArgs, eventName: "instructCard_posUpdt" });

      // Check if related section is visible, in order to determ. the card’s anchor el...
      // (if related section is hidden, card el. will be anchored to form comp. bottom edge)
      const relatedSectionState = this.state.sectionStates?.find((s) => s.id === relatedSectionId);
      const relatedSec_is_hidden = relatedSectionState?.is_hidden;

      // Compute card pos...
      const anchorEl = relatedSec_is_hidden ? compEl : relatedSectionState.el;
      const placement = relatedSec_is_hidden ? "left-end" : "left-start";
      computePosition(anchorEl, cardEl, { placement }).then(({ x, y }) => {
        cardEl.style.left = `calc(${x}px - 0.85rem)`;
        cardEl.style.top = `${y}px`;
      });
    });

    // Return instruct.-card state obj...
    return {
      el: cardEl,
      id,
      relatedSectionId,
      relatedStepId,
      is_hidden: true,
      is_active: false,
    };
  });
  this.setState({ instructCardStates });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", logArgs);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
