// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";
import format_cardData from "./util/format_cardData.js";
import get_highestCardZIndex from "./util/get_highestCardZIndex.js";
import get_cardFollowerEl from "./util/get_cardFollowerEl.js";
import get_cardRelatedModalEl from "./util/get_cardRelatedModalEl.js";
import set_initCardPositions from "./util/set_initCardPositions.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_participationWidget from "./init/init_participationWidget.js";
import init_contactForm from "./init/init_contactForm.js";
import init_surveyForm from "./init/init_surveyForm.js";
import init_cardFollower from "./init/init_cardFollower.js";
import init_ctaPopup from "./init/init_ctaPopup.js";
import init_filterMenu from "./init/init_filterMenu.js";
import init_audioPlayer from "./init/init_audioPlayer.js";
import init_cards from "./init/init_cards.js";
import init_marquee from "./init/init_marquee.js";
import init_strechedCardHeaderText from "./init/init_strechedCardHeaderText.js";
import init_strechedCardTags from "./init/init_strechedCardTags.js";
import init_animCardBorder from "./init/init_animCardBorder.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_cardStates from "./stateChangeHandlers/stChH_cardStates.js";
import stChH_filterMenuOpen from "./stateChangeHandlers/stChH_filterMenuOpen.js";
import stChH_activeCardFilters from "./stateChangeHandlers/stChH_activeCardFilters.js";
import stChH_cardDragEnabled from "./stateChangeHandlers/stChH_cardDragEnabled.js";

import filter_cards from "./api/filter_cards.js";
import distribute_cards from "./api/distribute_cards.js";
import expand_card from "./api/expand_card.js";
import disable_cardDrag from "./api/disable_cardDrag.js";
import disable_cardDrag_all from "./api/disable_cardDrag_all.js";
import enable_cardDrag_all from "./api/enable_cardDrag_all.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["cardplacmentmethod", "mobilebreakpoint"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class DragCards extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      cards: [],
      cardFollowers: [],
      filterMenu: null,
      openFilterMenuBtn: null,
      filterBtns: [],
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "DragCards",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);
    this.format_cardData = format_cardData.bind(this);
    this.get_highestCardZIndex = get_highestCardZIndex.bind(this);
    this.get_cardFollowerEl = get_cardFollowerEl.bind(this);
    this.get_cardRelatedModalEl = get_cardRelatedModalEl.bind(this);
    this.set_initCardPositions = set_initCardPositions.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_animCardBorder = init_animCardBorder.bind(this);
    this.init_surveyForm = init_surveyForm.bind(this);
    this.init_participationWidget = init_participationWidget.bind(this);
    this.init_contactForm = init_contactForm.bind(this);
    this.init_cardFollower = init_cardFollower.bind(this);
    this.init_ctaPopup = init_ctaPopup.bind(this);
    this.init_filterMenu = init_filterMenu.bind(this);
    this.init_strechedCardTags = init_strechedCardTags.bind(this);
    this.init_strechedCardHeaderText = init_strechedCardHeaderText.bind(this);
    this.init_marquee = init_marquee.bind(this);
    this.init_audioPlayer = init_audioPlayer.bind(this);
    this.init_cards = init_cards.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      cards: { instances: [] },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      enable_cardDrag_all: enable_cardDrag_all.bind(this),
      disable_cardDrag_all: disable_cardDrag_all.bind(this),
      disable_cardDrag: disable_cardDrag.bind(this),
      expand_card: expand_card.bind(this),
      distribute_cards: distribute_cards.bind(this),
      filter_cards: filter_cards.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_cardDragEnabled = stChH_cardDragEnabled.bind(this);
    this.stChL_filterMenuOpen = stChH_filterMenuOpen.bind(this);
    this.stChL_activeCardFilters = stChH_activeCardFilters.bind(this);
    this.stChL_cardStates = stChH_cardStates.bind(this);
    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("DragCards.api.enable_cardDrag_all", this.api.enable_cardDrag_all);
    eventbus.off("DragCards.api.disable_cardDrag_all", this.api.disable_cardDrag_all);
    eventbus.off("DragCards.api.disable_cardDrag", this.api.disable_cardDrag);
    eventbus.off("DragCards.api.expand_card", this.api.expand_card);
    eventbus.off("DragCards.api.distribute_cards", this.api.distribute_cards);
    eventbus.off("DragCards.api.submit_contactForm", this.api.submit_contactForm);
    eventbus.off("DragCards.api.filter_cards", this.api.filter_cards);
    eventbus.off("DragCards.api.hideComp", this.api.hideComp);
    eventbus.off("DragCards.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_states();
    this.init_cards();
    this.init_filterMenu();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({
      activeCardFilters: ["all"],
      is_mobile: window.innerWidth < 640,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("DragCards.api.enable_cardDrag_all", this.api.enable_cardDrag_all);
    eventbus.on("DragCards.api.disable_cardDrag_all", this.api.disable_cardDrag_all);
    eventbus.on("DragCards.api.disable_cardDrag", this.api.disable_cardDrag);
    eventbus.on("DragCards.api.expand_card", this.api.expand_card);
    eventbus.on("DragCards.api.distribute_cards", this.api.distribute_cards);
    eventbus.on("DragCards.api.submit_contactForm", this.api.submit_contactForm);
    eventbus.on("DragCards.api.filter_cards", this.api.filter_cards);
    eventbus.on("DragCards.api.hideComp", this.api.hideComp);
    eventbus.on("DragCards.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("cardDragEnabled" in CHANGES) this.stChL_cardDragEnabled(CHANGES);

    if ("filterMenuOpen" in CHANGES) this.stChL_filterMenuOpen(CHANGES);
    if ("activeCardFilters" in CHANGES) this.stChL_activeCardFilters(CHANGES);
    if ("cardStates" in CHANGES) this.stChL_cardStates(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default DragCards;
