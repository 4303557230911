// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_currentStep", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`curr. step: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_currentStep(CHANGES) {
  if (!("currentStep" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { currentStep } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(currentStep), "default", logArgs);

  // Updt. instruct.-card states
  // (show/hide instruct.- cards related to curr. step)...
  this.setState({
    instructCardStates: this.state.instructCardStates.map((cardState) => {
      const { relatedStepId } = cardState;
      const is_hidden = relatedStepId != currentStep;
      return { ...cardState, is_hidden };
    }),
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
