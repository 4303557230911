// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function calc_maxListItemScale(ITEMEL, WINDOWWIDTH = window.innerWidth) {
  if (!ITEMEL) return console.warn("calc_maxItemScale(): no item el. provided.");
  const itemElWidth = ITEMEL.offsetWidth; // ← use offsetWidth instead of getBoundingClientRect().width
  return WINDOWWIDTH / itemElWidth;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeListItem", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`active list item:`, CHANGE],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeListItem(CHANGES) {
  if (!("activeListItem" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);

  // Setup...
  const { activeListItem } = CHANGES;
  const { listItems } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeListItem), "default", { ...logArgs, inline: false });

  // Scale up active list item, scale down others...
  listItems.forEach((item) => {
    const itemID = item.dataset.id;
    const scale = itemID === activeListItem.id ? calc_maxListItemScale(item) : 1;
    item.style.setProperty("--scale-x-when-active", scale);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
