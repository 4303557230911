// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.disable_cardDrag_all", inline: true };
const loggerMsgs = {
  eventStart: ["DragCards", "API", "disable_cardDrag_all", "disabling all card dragging"],
  noDragCardInst: ["DragCards", "API", "disable_cardDrag_all", "no drag. card instance(s) found"],
  alreadyDisabled: ["DragCards", "API", "disable_cardDrag_all", "card dragging already disabled"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function disable_cardDrag_all() {
  // Guard: stop, if card dragging is already disabled...
  if (!this.state.cardDragEnabled) return this.logger("warning", loggerMsgs.alreadyDisabled, "warning", logArgs);

  // Setup...
  const dragCardInstances = this.modules.cards.instances;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Guard: stop, if no drag. card instances found...
  if (!dragCardInstances || !dragCardInstances.length) return this.logger("error", loggerMsgs.noDragCardInst, "error", logArgs);

  // Disable all drag. card instances...
  dragCardInstances.forEach((c) => c.dragInst[0].disable());
  this.setState({ cardDragEnabled: false });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
