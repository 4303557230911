// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "api.filter_cards", inline: true };
const loggerMsgs = {
  eventStart: ["DragCards", "API", "filter_cards"],
  inInitMode: ["DragCards", "API", "filter_cards", "in init mode", "stopping API hdl. execution"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function filter_cards(FILTERIDS = ["all"]) {
  // Setup...
  const { mode, cardStates } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);
  if (mode === "init") return this.logger("warning", loggerMsgs.inInitMode, "warning", logArgs);

  // Guard...
  if (!FILTERIDS) return console.warn("filter_cards(): no filter ID provided");
  if (!cardStates) return console.warn("filter_cards(): no card states found");

  // Filter cards...
  const newCardStates = cardStates.map((cardState) => {
    const cardTags = cardState.config.tags;
    if (!cardTags) return { ...cardState, state: { ...cardState.state, is_hidden: true } };
    if (FILTERIDS.includes("all")) return { ...cardState, state: { ...cardState.state, is_hidden: false } };
    return { ...cardState, state: { ...cardState.state, is_hidden: !FILTERIDS.some((filterID) => cardTags.includes(filterID)) } };
  });
  console.log("newCardStates", newCardStates);
  this.setState({ cardStates: newCardStates });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
