// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "stChH_activeListItem", inline: true };
const loggerMsgs = {
  stateChange: (CHANGE) => [`change: ${CHANGE}`],
  error_noStateChange: () => ["No change to state provided."],
  error_invalidStateValue: (VALUE) => [`invalid state value: ${VALUE}`],
};

const possibleStateValues = ["foo", "bar"];

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeListItem(CHANGES) {
  if (!("change" in CHANGES)) return this.logger("error", loggerMsgs.error_noStateChange(), "error", logArgs);
  if (!possibleStateValues.includes(CHANGES.change))
    return this.logger("error", loggerMsgs.error_invalidStateValue(CHANGES.change), "error", logArgs);

  // Setup...
  const { activeListItem } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(change), "default", logArgs);

  // [action]...
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
