// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ————————————————————————————————————————— PARENT CLASS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import BaseSlider from "../../component-base-slider/js/BaseSlider.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// extracted automatically from the options arg. but to be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["flickityconfig"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class BgSlider extends BaseSlider {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      ...super.ref,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "BgSlider",
      version: element.getAttribute("g-version") ?? "1",
      flickityConfig: options.flickityconfig,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    // this.logger = logger.bind(this);
    // this.validate_refEl = validate_refEl.bind(this);
    // this.cancel_featInit = cancel_featureInit.bind(this);
    // this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    // ...

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      flickity: { instance: this.init_flickity() },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      ...super.api,
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    // this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_mode = stChH_mode.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    super.mount();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    super.unmount();

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    // eventbus.off("window_resize", this.ebl_window_resize);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("Slider.api.hideComp", this.api.hideComp);
    eventbus.off("Slider.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    super.init();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  // init_states() {
  //   this.logger("init", ["states"], "action", { inline: true });
  //   this.setState({ mode: "init", is_mobile: window.innerWidth < 640 });
  // }

  ////////////////////////////////////
  ////////////////////////////////////

  // init_eventbus() {
  //   this.logger("init", ["eventbus"], "action", { inline: true });

  //   ///////////////////////////
  //   // Listener registration //
  //   ///////////////////////////

  //   eventbus.on("window_resize", this.ebl_window_resize);

  //   ///////////////////////////
  //   // API call registration //
  //   ///////////////////////////

  //   eventbus.on("BgSlider.api.hideComp", this.api.hideComp);
  //   eventbus.on("BgSlider.api.showComp", this.api.showComp);
  // }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    super.stateChange(CHANGES);

    // /////////////// Mode ///////////////
    // ////////////////////////////////////

    // if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default BgSlider;
