// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "*", inline: true };
const loggerMsgs = {
  eventStart: ["mode-picker-btn has been clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_modePickerBtn_click(BUTTONEL) {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // Enable mode & step corresponding to clicked btn...
  this.setState({
    mode: BUTTONEL.dataset.id,
    currentStep: BUTTONEL.dataset.id === "drawing" ? "selectCanvasSection" : "start",
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
