// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const logArgs = { eventName: "eh_listItem_mouseLeave", inline: true };
const loggerMsgs = {
  eventStart: ["mouse left list item"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_listItem_mouseLeave() {
  // Setup...
  this.logger("event", loggerMsgs.eventStart, "event", logArgs);

  // - Updt. active-list-item state...
  // - Updt. info-display state...
  this.setState({
    activeListItem: { id: null },
    infoDisplayState: {
      text: "",
      is_hidden: true,
    },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
