// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

/**
 * Determ. best position for an element on a page with no more than specified overlap.
 *
 * @param {HTMLElement} ELEMENT               - The element to place.
 * @param {number}      PAGEWIDTH             - The width of the page in pixels.
 * @param {number}      PAGEHEIGHT            - The height of the page in pixels.
 * @param {Array<{x: number, y: number, width: number, height: number}>} POSITIONS - The positions of the already placed elements.
 * @param {number}      maxOverlap            - The maximum allowed overlap between elements, as a percentage (0-1).
 * @param {number}      largeOverlapThreshold - The threshold for considering an overlap as "large", as a percentage (0-1).
 * @returns {{position: {x: number, y: number}, overlap: number, hasLargeOverlap: boolean}} - The best position for the element and the overlap ratio.
 */

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import calc_cardOverlapRatio from "./calc_cardOverlapRatio";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function determ_bestCardPosition_withOverlap(
  EL,
  PAGEWIDTH = window.innerWidth,
  PAGEHEIGHT = window.innerHeight,
  POSITIONS,
  CONFIG = { maxOverlap: 0.1, largeOverlapThreshold: 0.5 }
) {
  if (!EL) return console.warn("determ_bestCardPosition(): No element provided.");
  if (!POSITIONS) return console.warn("determ_bestCardPosition(): No positions provided.");

  // Setup...
  const { maxOverlap, largeOverlapThreshold } = CONFIG;
  const elWidth = EL.offsetWidth;
  const elHeight = EL.offsetHeight;
  let bestPosition = null;
  let bestOverlap = Infinity;
  let hasLargeOverlap = false;

  // Try to determ. best position (up to 10 attempts)...
  for (let i = 0; i < 10; i++) {
    const x = Math.floor(Math.random() * (PAGEWIDTH - elWidth));
    const y = Math.floor(Math.random() * (PAGEHEIGHT - elHeight));
    let overlap = 0;

    for (const position of POSITIONS) {
      const overlapRatio = calc_cardOverlapRatio(
        x,
        y,
        elWidth,
        elHeight,
        position.x,
        position.y,
        position.width,
        position.height
      );
      overlap += overlapRatio;
      if (overlapRatio > largeOverlapThreshold) {
        hasLargeOverlap = true;
        break;
      }
    }

    if (overlap <= maxOverlap && overlap < bestOverlap && !hasLargeOverlap) {
      bestPosition = { x, y, width: elWidth, height: elHeight };
      bestOverlap = overlap;
    }
  }

  return { position: bestPosition, overlap: bestOverlap, hasLargeOverlap };
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
