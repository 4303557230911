// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger";
import validate_refEl from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_drawingToolbar from "./init/init_drawingToolbar.js";
import init_sections from "./init/init_sections.js";
import init_canvasSections from "./init/init_canvasSections.js";
import init_fabric from "./init/init_fabric.js";
import init_form from "./init/init_form.js";
import init_questionBtns from "./init/init_questionBtns.js";
import init_modePicker from "./init/init_modePicker.js";
import init_instructCards from "./init/init_instructCards.js";

// ——————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS/API ——————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_enable from "./eventbusHandlers/ebh_swup_enable.js";
import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_currentStep from "./stateChangeHandlers/stChH_currentStep.js";
import stChH_sectionStates from "./stateChangeHandlers/stChH_sectionStates.js";
import stChH_selectedQuestion from "./stateChangeHandlers/stChH_selectedQuestion.js";
import stChH_selectedCanvasSect from "./stateChangeHandlers/stChH_selectedCanvasSect.js";
import stChH_selectedDrawTool from "./stateChangeHandlers/stChH_selectedDrawTool.js";
import stChH_instructCardStates from "./stateChangeHandlers/stChH_instructCardStates.js";

import refresh_canvasSize from "./api/refresh_canvasSize.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// Default log styles

const defaultLogStyles = {
  default: "#6682d6",
  action: "#c7d0ff",
  event: "#97a5ce",
  warning: "#ffaf00",
  error: "#ff3232",
  success: "#00c853",
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class PartiForm extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      sections: [],
      questionBtns: [],
      modePickerBtns: [],
      drawingToolbar: null,
      drawingCanvasWrapper: null,
      drawingCanvas: null,
      drawingCanvasSections: [],
      drawingCursor: null,
      pencilToolBtn: null,
      spraycanToolBtn: null,
      instructCards: [],
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "PartiForm",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
      //////
      // run_withLogs: false,
      // logStyles: defaultLogStyles,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEl = validate_refEl.bind(this);
    this.cancel_featInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_drawingToolbar = init_drawingToolbar.bind(this);
    this.init_canvasSections = init_canvasSections.bind(this);
    this.init_sections = init_sections.bind(this);
    this.init_instructCards = init_instructCards.bind(this);
    this.init_fabric = init_fabric.bind(this);
    this.init_modePicker = init_modePicker.bind(this);
    this.init_form = init_form.bind(this);
    this.init_questionBtns = init_questionBtns.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      fabric: { instances: [] },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      refresh_canvasSize: refresh_canvasSize.bind(this),
      hideComp: () => this.setState({ hidden: true }),
      showComp: () => this.setState({ hidden: false }),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_swup_enable = ebh_swup_enable.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_currentStep = stChH_currentStep.bind(this);
    this.stChL_sectionStates = stChH_sectionStates.bind(this);
    this.stChL_selectedCanvasSect = stChH_selectedCanvasSect.bind(this);
    this.stChL_selectedDrawTool = stChH_selectedDrawTool.bind(this);
    this.stChL_selectedQuestion = stChH_selectedQuestion.bind(this);
    this.stChL_instructCardStates = stChH_instructCardStates.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    // ...

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { eventName: "mount", inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { eventName: "unmount", inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("swup_enable", this.ebl_swup_enable);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("PartiForm.api.refresh_canvasSize", this.api.refresh_canvasSize);
    eventbus.off("PartiForm.api.hideComp", this.api.hideComp);
    eventbus.off("PartiForm.api.showComp", this.api.showComp);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.setState({ mode: "init" });
    this.init_form();
    this.init_canvasSections();
    this.init_drawingToolbar();
    this.init_sections();
    this.init_questionBtns();
    this.init_modePicker();
    this.init_instructCards();
    this.init_states();
    this.init_eventbus();
    this.setState({ mode: "ready" });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { eventName: "init_states", inline: true });
    this.setState({
      currentStep: "start",
      is_mobile: window.innerWidth < 640,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { eventName: "init_eventbus", inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("swup_enable", this.ebl_swup_enable);
    eventbus.on("window_resize", this.ebl_window_resize);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("PartiForm.api.refresh_canvasSize", this.api.refresh_canvasSize);
    eventbus.on("PartiForm.api.hideComp", this.api.hideComp);
    eventbus.on("PartiForm.api.showComp", this.api.showComp);
  }

  /////////////////////////// State management ///////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    if ("selectedDrawTool" in CHANGES) this.stChL_selectedDrawTool(CHANGES);

    if ("currentStep" in CHANGES) this.stChL_currentStep(CHANGES);
    if ("selectedCanvasSect" in CHANGES) this.stChL_selectedCanvasSect(CHANGES);
    if ("sectionStates" in CHANGES) this.stChL_sectionStates(CHANGES);
    if ("instructCardStates" in CHANGES) this.stChL_instructCardStates(CHANGES);
    if ("selectedQuestion" in CHANGES) this.stChL_selectedQuestion(CHANGES);
    if ("mode" in CHANGES) this.stChL_mode(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default PartiForm;
